<template>
  <div class="ud-body">
    <!-- 搜索表单 -->
    <a-card :bordered="true">
      <a-form
        :model="where"
        :label-col="{ md: { span: 8 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 16 }, sm: { span: 24 } }"
      >
        <a-row>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="分类名称:">
              <a-input
                v-model:value.trim="where.categoryName"
                placeholder="请输入活动分类名称"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="活动分类:">
              <a-select
                allow-clear
                placeholder="请选择活动分类"
                v-model:value="where.categoryId"
              >
                <a-select-option
                  v-for="item in categoryList"
                  :key="item.categoryId"
                  :value="item.categoryId"
                >
                  {{ item.categoryName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24" :xs="24">
            <a-form-item class="ud-text-right" :wrapper-col="{ span: 24 }">
              <a-space>
                <a-button type="primary" @click="reload">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <!-- 表格 -->
      <ud-pro-table
        ref="table"
        row-key="formId"
        :datasource="datasource"
        :columns="columns"
        v-model:selection="selection"
        :scroll="{ x: 'max-content' }"
      >
        <template #toolbar>
          <a-space>
            <a-button type="primary" @click="openEdit()">
              <template #icon>
                <plus-outlined />
              </template>
              <span>新建</span>
            </a-button>
            <a-button type="primary" danger @click="removeBatch">
              <template #icon>
                <delete-outlined />
              </template>
              <span>删除</span>
            </a-button>
          </a-space>
        </template>
        <template #pic="{ record }">
          <div class="image">
            <a-image :src="record.activityMainPic" :width="120" :height="90"></a-image>
          </div>
        </template>
        <template #modules="{ record }">
          <a-space>
            <a @click="openFieldList(record)">模板</a>
          </a-space>
        </template>
        <template #action="{ record }">
          <a-space>
            <a @click="openEdit(record)">修改</a>
            <a-divider type="vertical" />
            <a-popconfirm title="确定要删除此项吗？" @confirm="remove(record)">
              <a class="ud-text-danger">删除</a>
            </a-popconfirm>
          </a-space>
        </template>
      </ud-pro-table>
    </a-card>
    <!-- 编辑弹窗 -->
    <Edit v-model:visible="showEdit" :data="current" @done="reload" />
    <a-modal
      :width="1300"
      v-model:visible="showFieldList"
      :confirm-loading="loading"
      :footer="null"
    >
      <!-- <fromFieldList :formId="current === null ? 0 : current.formId || 0"/> -->
    </a-modal>
  </div>
</template>

<script>
import * as activityTemplateApi from '@/api/activity/activityTemplate.js'
import * as activityCategoryApi from '@/api/activity/activityCategory.js'
// import * as dictDataApi from '@/api/dict/dictData.js'
import Edit from './index-edit.vue'
// import fromFieldList from './field.vue'
import { createVNode } from 'vue'
import {
  PlusOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons-vue'
export default {
  name: 'activityFormList',
  components: {
    PlusOutlined,
    DeleteOutlined,
    Edit
    // fromFieldList
  },
  data() {
    return {
      isEditManaged: false,
      // 表格列配置
      columns: [
        {
          key: 'index',
          width: 48,
          customRender: ({ index }) => this.$refs.table.tableIndex + index
        },
        {
          title: '活动主图',
          dataIndex: 'activityMainPic',
          slots: { customRender: 'pic' }
        },
        {
          title: '活动名称',
          dataIndex: 'activityName'
        },
        {
          title: '活动分类',
          dataIndex: 'categoryName'
        },
        {
          title: '活动类型',
          dataIndex: 'activityType'
        },
        {
          title: '活动地址',
          dataIndex: 'address'
        },
        {
          title: '功能',
          slots: { customRender: 'modules' }
        },
        {
          title: '创建时间',
          dataIndex: 'createTime'
        },
        {
          title: '操作',
          key: 'action',
          width: '220px',
          align: 'center',
          slots: { customRender: 'action' }
        }
      ],
      // 表格搜索条件
      where: {},
      // 表格选中数据
      selection: [],
      // 是否显示编辑弹窗
      showEdit: false,
      showFieldList: false,
      current: null,
      // 活动分类
      categoryList: []
    }
  },
  mounted() {
    // this.queryDataStates()
    this.queryCategoryList()
  },
  methods: {
    datasource(option, callback) {
      activityTemplateApi
        .page({
          ...this.where,
          order: 'desc',
          sort: 'createTime',
          page: option.page,
          limit: option.limit
        })
        .then((res) => {
          if (res.code === 0) {
            res.data.map((item) => {
              if (item.isShow === 0) {
                item.isShowName = '否'
              } else if (item.isShow === 1) {
                item.isShowName = '是'
              }
            })
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    // 字符串转数组
    strToArr(str) {
      if (str) {
        return str.split(',')
      } else {
        return []
      }
    },
    // 数组转字符串
    arrToStr(arr) {
      if (arr.length > 0) {
        return arr.join(',')
      } else {
        return ''
      }
    },
    /* 打开编辑弹窗 */
    openEdit(row) {
      this.current = row
      this.showEdit = true
    },
    /* 打开编辑弹窗 */
    openFieldList(row) {
      this.current = row
      this.showFieldList = true
    },
    /* 刷新表格 */
    reload() {
      this.selection = []
      this.$refs.table.reload({ page: 1 })
    },
    // /* 重置搜索 */
    reset() {
      this.where = {}
      this.$nextTick(() => {
        this.reload()
      })
    },
    // /* 删除单个 */
    remove(row) {
      const hide = this.$message.loading('请求中..', 0)
      activityTemplateApi
        .deleteById(row.activityId)
        .then((res) => {
          hide()
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.reload()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
    },
    // /* 批量删除 */
    removeBatch() {
      if (!this.selection.length) {
        this.$message.error('请至少选择一条数据')
        return
      }
      this.$confirm({
        title: '提示',
        content: '确定要删除选中的项目吗?',
        icon: createVNode(ExclamationCircleOutlined),
        maskClosable: true,
        onOk: () => {
          const hide = this.$message.loading('请求中..', 0)
          const data = { data: this.selection.map((d) => d.activityId) }
          activityTemplateApi
            .deleteBatch(data)
            .then((res) => {
              hide()
              if (res.code === 0) {
                this.$message.success(res.msg)
                this.reload()
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              hide()
              this.$message.error(e.message)
            })
        }
      })
    },
    // 获取分类
    queryCategoryList() {
      activityCategoryApi
        .all()
        .then((res) => {
          if (res.code === 0) {
            this.categoryList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    }
    // 是否显示
    // queryDataStates() {
    //   dictDataApi
    //     .getDictData({ dictCode: 'isShow' })
    //     .then((res) => {
    //       if (res.code === 0) {
    //         this.isShowList = res.data
    //       } else {
    //         this.$message.error(res.msg)
    //       }
    //     })
    //     .catch((e) => {
    //       this.$message.error(e.message)
    //     })
    // }
  }
}
</script>

<style  scoped>
.image {
  width: 120px;
  height: 90px;
}

.image :deep(.ant-image-img) {
  height: 100%;
}
</style>
